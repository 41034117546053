import {
  generateRating,
  generateHeart,
  getSimpleValue,
} from './helpers';

export const manufacturerProductConfig = {
  titleFields: [
    { id: 'top-info-1', getValue: getSimpleValue('productName') },
    { id: 'top-info-2', getValue: getSimpleValue('manufacturer') },
    { id: 'top-info-3', getValue: product => `$${product.price}` },
  ],
  getTooltipContent: product => {
    return `
      <div class="title">This is sample data for demo purposes.</div>

      <div class="row">
        <span>${product.productDescription}</span>
      </div>

      <div class="row">
        ${generateRating(product)}
        <div class="pull-right">
          ${generateHeart()}
        </div>
      </div>
    `;
  },
};
