import { detect } from 'detect-browser';
import axios from 'axios';

const HTTP_REG_EXP = /https?:\/\//;

export function animateCSS(element, animationName, speedModifier, callback) {
  const node = document.querySelector(element);
  node.classList.add('animated', animationName, speedModifier);

  function handleAnimationEnd() {
    node.classList.remove('animated', animationName, speedModifier);
    node.removeEventListener('animationend', handleAnimationEnd);

    if (typeof callback === 'function') callback();
  }

  node.addEventListener('animationend', handleAnimationEnd);
}

export function drawCodeLocation(context, location) {
  context.beginPath();
  context.moveTo(location.topLeft.x, location.topLeft.y);
  context.lineTo(location.topRight.x, location.topRight.y);
  context.lineTo(location.bottomRight.x, location.bottomRight.y);
  context.lineTo(location.bottomLeft.x, location.bottomLeft.y);
  context.closePath();
  context.lineWidth = 3;
  context.strokeStyle = 'rgba(46, 192, 204, 0.6)';
  context.stroke();
  context.fillStyle = 'rgba(46, 192, 204, 0.3)';
  context.fill();
}

export function onWindowResize() {
  document.querySelector('.scan-screen').style.height = `${window.innerHeight}px`;
}

export function isBrowserAndroid() {
  const browser = detect();

  return browser && browser.os === 'Android OS';
}

export function loadData(baseUrl, id) {
  const url = id ? `${baseUrl}/${id}` : baseUrl;
  const normalizedUrl = HTTP_REG_EXP.test(url) ? url : `http://${url}`;

  return axios.get(normalizedUrl)
    .then(result => result.data && result.data.database && result.data.database.products);
}

export function isDataBaseResult(result, pattern) {
  const replacedPattern = pattern.replace(HTTP_REG_EXP, '').toLowerCase();
  const data = (result && result.barcodes && result.barcodes[0] && result.barcodes[0].data) || '';
  const replacedData = data.replace(HTTP_REG_EXP, '').toLowerCase();

  return replacedData.includes(replacedPattern) &&
    replacedData.length > replacedPattern.length + 1;
}
