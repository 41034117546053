export function generateHeart() {
  return `
    <input id="toggle" type="checkbox"/>
    <label for="toggle">
      <span class="round-button icon heart-empty"></span>
    </label>
  `;
}

export function generateRating(product) {
  let stars = '';

  for (let i = 0; i < 5; ++i) {
    stars += `<span class="icon ${i < product.avgStars ? 'star-filled' : 'star-empty'}"></span>`;
  }

  return stars;
}

export function generateRatingsCount(product) {
  return `<span class="ratings-count">( ${product.ratingsCount} )</span>`;

}

export function getSimpleValue(name) {
  return product => product[name];
}
