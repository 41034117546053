import { CONFIG } from '../config';
import { wineConfig } from './wine';
import { manufacturerProductConfig } from './manufacturer-product';
import { ingredientsProductConfig } from './ingredients-product';
import { showProduct } from './main';

const { MODES } = CONFIG;

export function showResult(product, mode, afterCloseCb) {

  switch (mode) {
    // when product was not found in DB, mode is null - use wine module with wine from DB
    case null:
    case MODES.WINE_LOOKUP:
      return showProduct(product, wineConfig, afterCloseCb);

    case MODES.FASHION_MARKDOWN:
    case MODES.FASHION_MARKDOWN_BACK_ROOM:
    case MODES.ELECTRONICS_APP:
    case MODES.TOOLS:
      return showProduct(product, manufacturerProductConfig, afterCloseCb);

    default:
      return showProduct(product, ingredientsProductConfig, afterCloseCb);
  }

}
