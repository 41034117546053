import Noty from 'noty';
import { animateCSS } from '../helpers';

function showProductTitle(product, config) {
  config.titleFields.forEach(field => {
    document.querySelector(`#${field.id}`).innerHTML = field.getValue(product);
  });

  animateCSS('#product-title-none', 'fadeOut', 'faster', () => {
    document.querySelector('#product-title-none').classList.add('hidden');
    document.querySelector('#product-title-info').classList.remove('hidden');
    animateCSS('#product-title-info', 'fadeIn', 'faster');
  });
}

export function showProductDetails(product, productConfig, afterCloseCallback) {

  document.querySelector('.code-location-canvas').classList.remove('hidden');
  animateCSS('.code-location-canvas', 'fadeIn', 'faster');

  const noty = new Noty({
    text: productConfig.getTooltipContent(product),
    type: 'success',
    layout: 'bottom',
    closeWith: [],
    killer: true,
    animation: {
      open: 'animated fadeInUp',
      close: 'animated fadeOutDown fast'
    },
    callbacks: {
      afterShow: () => {
        document.querySelector('video').addEventListener('touchstart', () => noty.close());

        document.addEventListener('click', function(event) {
          if (
            event.target instanceof HTMLElement &&
            (event.target.id === 'toggle' || event.target.classList.contains('round-button'))
          ) {
            event.stopPropagation();
          } else {
            noty.close();
          }
        });
      },
      onClose: () => {
        animateCSS('.code-location-canvas', 'fadeOut', 'fast', () => {
          document.querySelector('.code-location-canvas').classList.add('hidden');
        });
        animateCSS('#product-title-info', 'fadeOut', 'faster', () => {
          document.querySelector('#product-title-info').classList.add('hidden');
          document.querySelector('#product-title-none').classList.remove('hidden');
          animateCSS('#product-title-none', 'fadeIn', 'faster');
        });
      },
      afterClose: afterCloseCallback
    }
  });

  noty.show();

  return noty;
}

export function showProduct(product, productConfig, afterCloseCallback) {
  function popstateListener() {
    noty.close();
    window.removeEventListener('popstate', popstateListener);
  }

  showProductTitle(product, productConfig);

  const noty = showProductDetails(product, productConfig, () => {
    afterCloseCallback();
    window.removeEventListener('popstate', popstateListener);
  });

  history.pushState('product_info', '');
  window.addEventListener('popstate', popstateListener);
}
