import {
  configure,
  BarcodePicker,
  ScanSettings,
} from 'scandit-sdk';

import {
  animateCSS,
  drawCodeLocation,
  onWindowResize,
  loadData,
  isDataBaseResult,
  isBrowserAndroid,
} from './helpers'

import {
  showAppNotification,
  showLoadDatabaseNotification,
} from './noty';

import {
  showResult,
} from './products';

import {
  CONFIG,
} from './config';


// MAIN SCRIPT START

const codeLocationCanvasElement = document.createElement('canvas');
codeLocationCanvasElement.classList.add('code-location-canvas');
// Set the code location canvas to fit in its parent element in the same way as the camera video inside the barcode picker
// Second value used by 'objectFitPolyfill' library
codeLocationCanvasElement.style.objectFit = BarcodePicker.ObjectFit.COVER;
codeLocationCanvasElement.dataset.objectFit = BarcodePicker.ObjectFit.COVER;

const codeLocationCanvasContext = codeLocationCanvasElement.getContext('2d');

configure(CONFIG.SCANDIT_CONFIGURE.LICENSE_KEY, CONFIG.SCANDIT_CONFIGURE.SCANDIT_CONFIGURE_OPTIONS)
  .then(() => BarcodePicker.create(document.getElementById('barcode-picker'), CONFIG.BARCODE_PICKER_SETTINGS))
  .then(barcodePicker => {
    // Insert the code location canvas inside the scandit barcode picker element
    document.getElementsByClassName('scandit-barcode-picker')[0].appendChild(codeLocationCanvasElement);

    const scanSettings = new ScanSettings(CONFIG.SCAN_SETTINGS);
    barcodePicker.applyScanSettings(scanSettings);

    let appNotificationTimeout;

    barcodePicker.on('scan', function(scanResult) {
      clearTimeout(appNotificationTimeout);
      barcodePicker.pauseScanning();

      if (isDataBaseResult(scanResult, CONFIG.DB_URL)) {
        loadData(scanResult.barcodes[0].data)
          .then(result => {
            products = result;
            showLoadDatabaseNotification();
          })
          .catch(console.error)
          .finally(() => barcodePicker.resumeScanning())

        return;
      }

      const foundProduct = products.find(product_ => product_.code === scanResult.barcodes[0].data);
      const fallbackProducts = products.filter(product_ => product_.mode === CONFIG.DEFAULT_MODE);
      const asciiSum = scanResult.barcodes[0].data.split("")
        .reduce((sum, char) => sum + (char.charCodeAt() || 0), 0)

      const fallbackIndex = asciiSum % fallbackProducts.length;
      const product = foundProduct || fallbackProducts[fallbackIndex || 0];
      const mode = foundProduct ? product.mode : null;

      showResult(product, mode, () => barcodePicker.resumeScanning());
      // Erase previous code location
      codeLocationCanvasContext.clearRect(
        0,
        0,
        codeLocationCanvasContext.canvas.width,
        codeLocationCanvasContext.canvas.height
      );
      // Adjust code location canvas context size based on currently active camera resolution
      codeLocationCanvasContext.canvas.width = scanResult.imageSettings.width;
      codeLocationCanvasContext.canvas.height = scanResult.imageSettings.height;
      // Mirror code location canvas if the camera image is also mirrored
      if (barcodePicker.isMirrorImageEnabled()) {
        codeLocationCanvasElement.classList.add('mirrored');
      } else {
        codeLocationCanvasElement.classList.remove('mirrored');
      }
      // Draw scan result's frame
      codeLocationCanvasContext.putImageData(
        new ImageData(scanResult.imageData, scanResult.imageSettings.width, scanResult.imageSettings.height),
        0,
        0
      );
      // Draw scan result's location
      drawCodeLocation(codeLocationCanvasContext, scanResult.barcodes[0].location);
    });
    barcodePicker.on('scanError', console.error);

    document.querySelector('#spinner').classList.add('hidden');
    document.querySelector('#start-scanning-button').removeAttribute('disabled');
    document.querySelector('#start-scanning-button').addEventListener('click', () => {
      animateCSS('.start-screen', 'fadeOut', 'fast', () => {
        document.querySelector('.start-screen').classList.add('hidden');
        document.querySelector('.scan-screen').classList.remove('hidden');
        barcodePicker.resumeScanning();
        animateCSS('.scan-screen', 'fadeIn', 'slower', () => {
          appNotificationTimeout = setTimeout(() => showAppNotification(CONFIG.APP_DATA[isBrowserAndroid() ? 'Android' : 'iOSGeneric']), 3000);
        });
      });
    });
  })
  .catch(alert);

// MAIN SCRIPT END


// Load and set products
let products = [];
loadData(CONFIG.DB_URL, CONFIG.DB_ID)
  .then(result => products = result)
  .catch(console.error);

// Correctly size fullscreen content height on all platforms
window.addEventListener('resize', onWindowResize);
onWindowResize();
