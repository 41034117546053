import { Barcode, BarcodePicker } from 'scandit-sdk';

// public variables to be changed
const DB_URL = 'https://ssl.scandit.com/api/public/demo_app_deployments';
const DB_ID = 'e5a7fcc7438ab61d13972d562b77291cf812be8f91b9318119653e128315cf37';

const MODES = {
  WINE_LOOKUP: 'Wine Lookup',
  ELECTRONICS_APP: 'Electronics-App',
  TOOLS: 'Tools',
  FASHION_MARKDOWN: 'FashionMarkdown',
  FASHION_MARKDOWN_BACK_ROOM: 'FashionMarkdown, Backroom Search',
}

const DEFAULT_MODE = MODES.WINE_LOOKUP;

const APP_DATA = {
  'Android': {
    storeName: 'Play',
    url: 'https://play.google.com/store/apps/details?id=com.scandit.demoapp&hl=en'
  },
  'iOSGeneric': {
    storeName: 'App',
    url: 'https://apps.apple.com/app/scandit-barcode-scanner/id453880584'
  }
};

const SCAN_SETTINGS = {
  enabledSymbologies: [
    Barcode.Symbology.QR,
    Barcode.Symbology.DATA_MATRIX,
    Barcode.Symbology.EAN13,
    Barcode.Symbology.UPCA,
    Barcode.Symbology.UPCE,
    Barcode.Symbology.CODE128,
    Barcode.Symbology.CODE39,
  ],
  searchArea: {
    x: 0.05,
    y: 0.25,
    width: 0.9,
    height: 0.5
  },
  codeDuplicateFilter: 1500,
};

const BARCODE_PICKER_SETTINGS = {
  playSoundOnScan: true,
  vibrateOnScan: true,
  videoFit: BarcodePicker.ObjectFit.COVER,
  scanningPaused: true,
  enableCameraSwitcher: false,
};

const LICENSE_KEY = window.WebsdkDemos.getLicenseKeyByHostname(window.location.hostname);
const SCANDIT_CONFIGURE_OPTIONS = {
  engineLocation: '/'
};

export const CONFIG = {
  DB_URL,
  DB_ID,

  SCAN_SETTINGS,
  BARCODE_PICKER_SETTINGS,
  SCANDIT_CONFIGURE: {
    LICENSE_KEY,
    SCANDIT_CONFIGURE_OPTIONS,
  },

  DEFAULT_MODE,
  MODES,

  APP_DATA,
}
