import Noty from 'noty';

const DEFAULT_NOTIFICATION = {
  type: "info",
  layout: "bottom",
  timeout: 10000,
  progressBar: false,
  animation: {
    open: "animated fadeIn fast",
    close: "animated fadeOutDown faster"
  }
};

function getAppNotyConfig(appData) {
  return {
    ...DEFAULT_NOTIFICATION,
    text: `We also have a native app with an even richer feature set. Download it
    <a target="_blank" href="${appData.url}">here</a> from the ${appData.storeName} Store.`,
  };
};

function getLoadDatabaseNotyConfig() {
  return {
    ...DEFAULT_NOTIFICATION,
    text: '<h3>New database was loaded successfully</h3>',
    timeout: 5000,
  };
};

export function showAppNotification(appData) {
  new Noty(getAppNotyConfig(appData)).show();
}


export function showLoadDatabaseNotification() {
  new Noty(getLoadDatabaseNotyConfig()).show();
}
